<template>
    <div class="eventDetail_box">
         <!-- 加载动画 -->
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
        <Header ref="Header"></Header>
        <div class="eventDetail_cont">
            <div class="info_top clearfix">
                <!-- 有赛事信息的图片 -->
                <img :src="eventInfo.eventImage|dealEventimg" class="info_img" :alt="eventInfo.name" v-if="eventInfo.eventImage">
                <!-- 无赛事信息图片 -->
                <img src="" class="info_img" alt="" v-else>
                <div class="right_info">
                    <span class="info_title text_over" v-if="eventInfo.name" v-cloak>{{eventInfo.name}}</span>
                    <div class="info_show">
                        <template  v-if="eventInfo.status==0" v-cloak>
                            <span class="info_time">抢购未开始：{{coutime}}</span>
                        </template>
                        <template  v-else-if="eventInfo.status==1" v-cloak>
                            <span class="info_time">立即抢购：{{coutime}}</span>
                        </template>
                        <template  v-else-if="eventInfo.status==2" v-cloak>
                            <span class="info_time">即将抢购：{{coutime}}</span>
                        </template>
                        <template  v-else-if="eventInfo.status==3" v-cloak>
                            <span class="info_time">抢购进行中</span>
                        </template>
                        <template  v-else v-cloak>
                            <span class="info_time">抢购已完成</span>
                        </template>
                        <!-- <span class="info_time" v-if="eventInfo.matchEndDate">比赛时间：{{matchDate|dealTime}}</span> -->
                        <div class="share clearfix">
                            <span class="f_left">分享到：</span>
                            <i :class="['weixin_icon f_left',{'act':isshowShare}]" @click="showShare"
                            @mouseleave="hideShare"></i>
                            <!-- 分享code -->
                            <div class="code_share" v-show="isshowShare">
                                <vue-qr :text="shareUrl" :size="111" :margin="0" :dotScale = '1' class="shareImg"></vue-qr>
                                <span class="txt">使用微信扫一扫</span>     
                            </div>
                        </div>
                    </div>
                    <div class="handle_cz act clearfix" v-show="!(userInfo!=undefined && eventInfo.status==1)" v-cloak>
                        <button v-if="eventInfo.isCollection==true" class="unfollowed followed f_left" @click.stop="followed()">已关注</button>
                        <button v-else class="unfollowed  f_left "  @click.stop="followed()">+关注</button>
                        <button class="apply f_left notapply" v-if="eventInfo.status==0" disabled>抢购未开始</button>
                        <button class="apply f_left" @click="apply_submit" v-else-if="eventInfo.status==1">立即抢购</button>
                        <button class="apply f_left notapply" v-else-if="eventInfo.status==2" disabled>即将抢购</button>
                        <button class="apply f_left" v-else-if="eventInfo.status==3" disabled>抢购进行中</button>
                        <button class="apply f_left notapply" v-else>抢购已完成</button>
                    </div>
                </div>
                <div class="score">
                    <span class="score_num" v-if="eventInfo.totalStar">{{score}}分</span>
                    <span class="score_txt">{{scoreTxt}}</span>
                    <span class="lookmore" @click.stop="lookComments">查看全部评论></span>
                </div>
                <div class="match_types" v-show="userInfo!=undefined && eventInfo.status==1" v-cloak>
                    <span class="match_txt">可选商品：</span>
                    <div class="matchs">
                        <ul class="match_lists clearfix" :style="eventType.length<=8?'height:auto;':isSlide?'height:auto;':'height:156px;overflow:hidden;'">
                            <li :class="['match_item f_left text_over', {'active': eventType_num===index}]"
                            v-for="(item,index) in eventType" :key="index"
                            @click="chooseType(item,index)">￥{{item.fee|dealcount}}  {{item.name}}</li>
                        </ul>
                        <div class="handle_cz clearfix">
                            <button v-if="eventInfo.isCollection==true" class="followed f_left" @click.stop="followed()">已关注</button>
                            <button v-else class="unfollowed f_left"  @click.stop="followed()">+关注</button>
                            <button class="apply f_left" @click="apply_submit">立即抢购</button>
                            <div class="silde f_right" v-show="eventType.length>8 && !isSlide" @click="slideTypes">
                                <span class="slide_txt f_left">展开全部</span>
                                <i class="slide_icon f_left"></i>
                            </div> 
                        </div>
                    </div>
                   
                </div>
            </div>
            <ul class="info_navs clearfix">
                <li :class="select_nav == index ? 'nav_item nav_act f_left' : 'nav_item f_left'"
                    v-for="(nav,index) in info_navs"
                    :key="index"
                    @click="chooseNav(index)">
                    {{nav}}
                </li>
            </ul>
            <div class="info_main">
                <template v-if="select_nav == 0">
                    <div class="event_intro_info">
                        <m-html-panel :url.sync="eventInfo.url" width="100%"></m-html-panel>
                    </div>
                </template>
                <!-- <template v-if="select_nav == 1">
                    <div class="event_nav_info" :style="selevt_num_url?'height:auto;':'height: 900px;'">
                        <template v-if="eventNavs.length">
                            <div class="event_navs" >
                                <template v-if="isShow_navs">
                                    <ul class="navs">
                                        <li :class="select_num === nav.id?'active event_nav':'event_nav'"
                                        v-for="(nav,index) in eventNavs" 
                                        :key="index"
                                        @click="clickEventnav(nav)">{{nav.title}}</li>
                                    </ul>
                                    <span class="nav_icon"></span>
                                </template>
                                
                                <div class="alertBox" v-if="isShowTel">
                                    <span class="tel">组委会电话：{{phoneInfo.organcommitteePhone}}</span>
                                    <span class="tel">工作日：{{phoneInfo.workingStart|dealworkTime}}-{{phoneInfo.workingEnd|dealworkTime}}</span>
                                    <button class="makesure" @click="makesure">好的</button>
                                </div>
                            </div>
                            <m-html-panel :url.sync="selevt_num_url" width="100%"></m-html-panel>
                        </template>
                        <div v-else class="nolist" style="">
                            <img src="../../assets/icons/eventdetail_not.png" alt="">
                            <span>该赛事还没有赛事导航信息哦~</span>            
                        </div>
                    </div>
                </template>
                <template v-if="select_nav == 2">
                    <div class="lists_type2  clearfix">
                        <template v-if="eventNews.length">
                            <div class="new_item clearfix" 
                            v-for="(item,index) in eventNews" 
                            :key="index"
                            @click="goNewdetail(item.id)">
                                <template v-if="item.image">
                                    <img :src="item.image[0]" alt="图片资源找不到" class="lists_item_img f_left">
                                </template>
                                <template v-else>
                                    <img src="" alt="图片资源找不到" class="lists_item_img f_left">
                                </template>
                                <span class="item_right_title text_twoLine">
                                {{item.title}}
                                </span>
                                <div class="attention">
                                    <i class="attention_icon f_left"></i>
                                    <span class="attention_num f_left">{{item.hits}}</span>
                                </div>
                            </div>
                            <div class="pages" v-if="totalnum!=0">
                                <el-pagination
                                background
                                @current-change="pageChange"
                                layout="prev,pager,next"
                                :total="totalnum">
                                </el-pagination>
                            </div>    
                        </template>
                        <div class="nolist" v-else>
                            <img src="../../assets/icons/eventdetail_not.png" alt="">
                            <span>该赛事还没有相关新闻哦~</span>            
                        </div>
                        
                    </div>
                </template> -->
                <template v-if="select_nav == 1">
                    <Comment
                     :info="commentInfo"
                     :eventId="eventId"
                     @commentEnd="commentEnd"
                     @isLogined="isLogined"
                     @pageChange="pageChange1"
                     ></Comment>
                </template>
                <template v-if="select_nav == 2">
                    <Ask
                    :info="askInfo"
                    :eventId="eventId"
                    @askEnd="askEnd"
                    @isLogined="isLogined"
                    @pageChange="pageChange2"
                    ></Ask>
                </template>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Comment from './eventAsk/eventcomment'
import Ask from './eventAsk/eventAsk'
import {eventDetail,update, event,Like} from '../../config/request'
import {formatDuring,getDate} from '../../config/utils';
import vueQr from 'vue-qr'
import { setTimeout } from 'timers';
export default {
    components:{
        Comment,
        Ask,
        vueQr
    },
    inject:['reload'],
    filters: {
        // 竞赛时间截取前11个字符
        dealTime(v){
            return v.length>11 ? v.substr(0,11)+'' : v;
        },
        // 若为3张图显示第1张
        dealimg(v) {
            return v.substr(0,v.indexOf('|'));
        },
        //时间截取5到10的字符
        dealTime2(v) {
            return v.substr(5,6);
        },
        // 标题只取前15个字符
        filterStrlen(str) {
            return str.length>15 ? str.substr(0,15)+'...' : str;
        },
        // 新闻内容取30个字
        dealCont(v) {
            return v.length>30 ? v.substr(0,30)+'...' : v;
        },
        //评分保留一位小数
        dealscore(v) {
            return v.toFixed(1);
        },
        //价钱显示两位小数
        dealcount(v) {
            return v.toFixed(2);
        },
        //工作时间截取到分
        dealworkTime(v) {
            return v.substr(0,5);
        },
        // 赛事图片处理
        dealEventimg(v) {
            if(v.indexOf('http') == 0) {
                return v;
            }else {
                return v.substr(v.indexOf('http'));
            }
        }
    },
    data() {
        return {
            userInfo:null,//用户信息
            eventId:'',//地址栏赛事id
            eventInfo:'',//详情信息
            coutime:'',//时间倒计时
            matchDate:'',//比赛时间
            eventType:[],//参赛类别
            eventType_num:'',//选择参赛类别
            entryId:'',//选择参赛类别的id
            title:'2019贵州·镇宁黄果树国际半程马拉松赛',
            score:5.0,//评分
            scoreTxt:'非常棒',//评分评价
            isfollow:false,//是否关注
            follow_index:'',
            info_navs:['介绍','点评','问大家'],
            isShow_navs:true,
            select_nav:0,//默认选择赛事介绍
            iframeHeight:'',
            isShowTel:false,//是否显示组委会电话
            eventNavs:[],//赛事导航列表
            eventNews:[],//赛事新闻列表
            totalnum:52,//赛事新闻条数
            select_num:'',//赛事导航中激活的nav
            selevt_num_url:'',//赛事导航nav对应的url
            phoneInfo:'',//组委会电话信息
            bg_show:false,
            commentInfo:'',//点评信息
            askInfo:'',//问大家信息
            shareUrl:'',//分享二维码地址
            isshowShare:false,//显示分享弹框
            isSlide:false,
            timehandle:null,
            isLoading:true
        }
    },
    methods: {
        // 关注
        followed() {
            if(!sessionStorage.getItem('userInfo')) {
                this.isLogined();
                this.eventInfo.isCollection = false;
            }else {
                let id = this.eventId;
                let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
                let accountId = userInfo.id
                Like({
                    type:2,
                    id:id,
                    accountId:accountId
                }).then(res=> {
                    console.log(res)
                    // 关注成功
                    if(res.code ==1 &&res.objectData ==2) {
                        this.isfollow = true;
                        this.eventInfo.isCollection = true;
                    }else if(res.code ==1 &&res.objectData ==1) {
                        //取关
                        this.isfollow = false;
                        this.eventInfo.isCollection = false;
                    }
                })
            }
        },
        chooseType(item,index) {
            // 选择赛事类别
            this.eventType_num = index;
            this.entryId = item.entryId;
            // 存储选择的赛事类别
            sessionStorage.setItem('eventType',JSON.stringify(item));
        },
        //点击选择nav
        chooseNav(index) {
            this.select_nav = index;
            // 点击赛事介绍
            if(index == 0) {
            }
            // 点击赛事导航
            if(index == 1){
                // this.getEventnavs();
                // this.select_num = '';
                // this.isShow_navs = true;
                this.getcommentsList();
            }
            // 点击赛事新闻获取新闻列表
            if(index == 2) {
                // this.getEventnews();
                this.getAsklist();
            }
            //点击赛事点评
            // if(index == 3) {
            //     this.getcommentsList();
            // }
            // // 点击问大家
            // if(index == 4) {
            //     this.getAsklist();
            // }
        },
        // 点击组委会电话
        showTel() {
            this.isShowTel = true;
        },
        // 确认已知电话
        makesure() {
            this.isShowTel = false;
        },
        changeMobsfIframe() {
                const deviceHeight = document.body.clientHeight
                // console.log(deviceHeight)
                this.iframeHeight = (Number(deviceHeight) - 80) + 'px' // 数字是页面布局高
        },
        //赛事新闻分页改变时
        pageChange(page) {
        },
        // 跳到指定新闻详情页
        goNewdetail(newsId) {
            this.$router.push({
                path:'/newsDetail',
                query:{
                    articleId:newsId
                }
            })
        },
        // 根据赛事id获取赛事详情
        getEventinfo() {
            let accountId = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).id:'';
            if(this.eventId) {
                eventDetail.info({
                    module:'ssjj',
                    event:this.eventId,
                    accountId:accountId
                }).then(res => {
                    // console.log(res)
                    this.isLoading = false;
                    if(res.code == 1) {
                        this.eventInfo = res;
                        this.countdown();
                        // 处理时间格式
                        this.matchDate = getDate(res.matchStartDate);
                        this.score = res.totalStar.toFixed(1);
                        // 处理评分文案
                        if(res.totalStar>=1&& res.totalStar<=2) {
                            this.scoreTxt = '待改进';
                        }else if(res.totalStar>2 && res.totalStar<=3) {
                            this.scoreTxt = '一般';
                        }else if(res.totalStar>3 && res.totalStar<=4) {
                            this.scoreTxt = '满意';
                        }else {
                            this.scoreTxt = '非常棒';
                        }
                        document.getElementById('article_tit').innerHTML = res.name+'-马拉松报名';
                    }
                })
            }
        },
        // 获取赛事新闻
        getEventnews() {
            if(this.eventId) {
                eventDetail.news({
                    event:this.eventId,
                    module:'wjhg',
                    pageNo:1
                }).then(res => {
                    console.log(res)
                    if(res.code == 1 && res.list.length) {
                        let newsArr = []
                        res.list.forEach(item => {
                            // 图片和视频处理
                            let imageArr = []
                            let liClass = ""
                            let isVideo = !item.image.indexOf('mp4') || !item.image.indexOf('flash')
                            if(isVideo) { // 视频
                                imageArr = item.image
                                liClass = 'news_video'
                            } else { // 图片
                                item.image = item.image.split('|')
                                item.image.forEach(subItem => {
                                    if(subItem != "" && subItem.indexOf("http") != -1) {
                                        imageArr.push(subItem)
                                    }
                                })
                                // 新闻图片3张以下的都按一张图片的页面样式展示
                                if(imageArr.length < 3) {
                                    imageArr = imageArr.slice(0, 1) 
                                    liClass = "news_one_img"
                                } else {
                                    imageArr = imageArr.slice(0, 3)
                                    liClass = "news_more_img"
                                }
                            }
                            newsArr.push({
                                id: item.id,
                                hits: item.hits,
                                image: imageArr,
                                title: item.title,
                                isVideo: isVideo,
                                liClass: liClass
                            })
                        })
                        this.eventNews = newsArr;
                        this.totalnum = res.pagelist.count;
                    }
                })
            }
           
        },
        //获取赛事导航 
        getEventnavs() {
            if(this.eventId) {
                eventDetail.navs({
                    eventId:this.eventId,
                }).then(res => {
                    console.log(res)
                    if(res.code ==1 && res.list.length) {
                        this.eventNavs = res.list;
                    }
                })
            }
        },
        // 获取赛事点评列表
        getcommentsList(page) {
            let accountId = this.userInfo==undefined?"":this.userInfo.id;
            let pageNo = page?page:1;
            eventDetail.comment({
                eventId:this.eventId,
                pageNo:pageNo,
                pageSize:10,
                accountId
            }).then(res => {
                // console.log(res)
                if(res.code == 1) {
                    this.commentInfo = res;
                }

            })
        },
        // 获取赛事问大家列表
        getAsklist(page) {
            let accountId = this.userInfo==undefined?"":this.userInfo.id;
            let pageNo = page?page:1;
            eventDetail.ask({
                eventId:this.eventId,
                pageNo:pageNo,
                pageSize:10,
                accountId
            }).then(res => {
                // console.log(res)
                if(res.code == 1) {
                    this.askInfo = res;
                }
            })
        },
        // 赛事点评评论点赞完成后
        commentEnd() {
            this.getcommentsList();
        },
        //问大家评论点赞后
        askEnd() {
            this.getAsklist();
        },
        //获取赛事类别
        getEventypes() {
            eventDetail.type({
                mobile:this.userInfo.mobile,
                eventId:this.eventId,
                pageNo:1
            }).then(res => {
                // console.log(res)
                if(res.code == 1 &&res.eventList) {
                    this.eventType = res.eventList;
                }
            })
        },
        // 展开赛事类别
        slideTypes() {
            this.isSlide = true;
        },
        // 点击赛事导航内的nav获取对应的url
        clickEventnav(nav) {
            this.select_num = nav.id;
            this.isShow_navs = false;
            if(nav.html && nav.phone == undefined) {
                this.isShowTel = false;
                this.selevt_num_url = nav.html;
                this.phoneInfo = "";
                this.bg_show= false;
            }else {
                this.selevt_num_url = '';
                this.phoneInfo = JSON.parse(nav.phone);//获取组委会电话信息
                this.isShowTel = true;
                this.bg_show= true;
            }

        },
        //时间倒计时
        countdown() {
            this.timehandle = setTimeout(()=> {
                let timeCur;
                let info = this.eventInfo;
                var name = "";
                var curTime = new Date().getTime();
                if (info.status == 0) {
                    var times = info.startDate;
                    var sub = times - curTime;
                    name = formatDuring(sub);
                } else if (info.status == 1) {
                    var times = info.endDate;
                    var sub = times - curTime;
                    name = formatDuring(sub);
                } else if (info.status == 2) {
                    var times = info.matchStartDate;
                    var sub = times - curTime;
                    name = formatDuring(sub);
                }
                timeCur = name;
                this.coutime = timeCur;
                this.countdown();
            }, 1000)
        },
        //立即抢购
        apply_submit() {
            if(!sessionStorage.getItem('userInfo')) {
                this.isLogined();
            }else {
                if(this.eventInfo.isNative == 1) {
                    // 跳到第三方
                    window.open(this.eventInfo.outUrl, '_blank');
                }else{
                    // 正常报名
                    if(this.entryId!="") {
                        // 进入订单页 赛事id，选择类别id
                        this.$router.push({
                            path:'/order',
                            query:{
                                entryId:this.entryId,
                                eventId:this.eventId
                            }
                        })
                    }else {
                        this.$alert('请选择商品!','消息提示',{
                        dangerouslyUseHTMLString: true,
                        });
                    }
                } 
            }
        },
        //查看全部评论
        lookComments() {
            this.select_nav = 1;
            setTimeout(()=> {
                // 跳到赛事点评处
                var ruleTop = $('.info_navs')[0].offsetTop-$('.info_navs')[0].clientHeight;//滚动的位置；
                document.documentElement.scrollTop = ruleTop;
                // window.scrollTo({ 
                //     top: ruleTop, 
                //     behavior: "smooth" 
                // })
            },0)
        },
        // 弹出登录弹框
        isLogined() {
            // 弹出登录框
            this.$refs.Header.Login();
        },
        //点击分享出现二位码弹框
        showShare() {
            this.isshowShare = !this.isshowShare;
            this.shareUrl = 'https://h5.marathonbm.com/#/malasmain?event='+this.eventId;
        },
        hideShare() {
            this.isshowShare = false;
        },
        // 点评分页时
        pageChange1(page) {
            this.getcommentsList(page);
        },
        // 问大家点击分页时
        pageChange2(page) {
            this.getAsklist(page);
        }
    },
    mounted() {
        // 获取地址栏赛事id
        if(this.$route.query.eventId) {
            this.eventId = this.$route.query.eventId;
        }else {
            this.eventId = '585f4d0ced0d435a89a41e54c8476e52';
            // this.eventId = this.$route.query.eventId ? this.$route.query.eventId :'585f4d0ced0d435a89a41e54c8476e52'
        }
        // 用户是登录中状态时获取赛事类别,用户信息
        if(sessionStorage.getItem('userInfo')) {
            this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
            this.getEventypes();
        }
        // 收藏要查看详情时：定位到赛事详情的点评或者问大家
        // 若不是从收藏跳转过来的，默认选择赛事详情的赛事简介
        this.select_nav = this.$route.query.selectNav || 0
        if(this.$route.query.selectNav) {
            this.chooseNav(this.$route.query.selectNav)
        }
        this.getEventinfo();
        this.getcommentsList();
    },
    destroyed() {
        window.clearTimeout(this.timehandle)
    }
      
}
</script>

<style>
@import './style/eventDetail.css'
</style>
